<template>
<div>
    <template v-for="(item,index) in this.$store.state.operator.ordersList">
        <OperatorOrderItem :key="index" :nextStatus='3' currentStatus='status_id=[1,5,7,8]' :item="item"/>
    </template>
    
</div>
</template>

<script>
import OperatorOrderItem from '@/components/OperatorOrderItem.vue'
export default {
    components: {
        OperatorOrderItem
    },
     head: {
        title: {
            inner: 'История',
            separator: '-',
            complement: 'Оператор'
        },
    },
    async mounted() {
           await this.$store.dispatch("actionGetListOrder", 'status_id=[1,5,7,8]');
    },
}
</script>
