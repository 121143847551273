<template>
<div>
    <div class="admin-order-wrapper">
        <div class="container">
            <div class="row adminOrder mb-3">
                <div class="col-md-12 adminOrder__header">
                    <div class="adminOrder__header-number">
                        <b>Заказ №{{item.unique_code}}</b>
                        <p v-if="this.$route.path!='/operator/history'&item.type_of_eating!=null">{{item.type_of_eating.title}} ({{item.type_of_eating.time_start}}-{{item.type_of_eating.time_end}})</p>
                        <div class="showFullOrder mt-2" @click="toggle(item.id)" :class="{ opened: opened.includes(item.id) }">
                            <span v-if="opened.includes(item.id)">Свернуть</span>
                            <span v-else>Смотреть заказ</span>
                        </div>
                    </div>
                    <div class="adminOrder__header-type" v-if="this.$route.path=='/operator/history'&item.type_of_eating!=null">
                        {{item.type_of_eating.title}} ({{item.type_of_eating.time_start}}-{{item.type_of_eating.time_end}})
                    </div>
                    <div class="adminOrder__header-time" :class="{orange: opened.includes(item.id) }">
                        Время выдачи: {{item.selected_time}}
                    </div>
                </div>
                <div class="col-md-12" v-if="opened.includes(item.id)">
                    <div class="row gx-3">
                        <div class="col-md-4" v-for="good in item.products" :key="good.id">
                            <div class="adminOrderItem">
                                <div class="adminOrderItem__number" :style="{backgroundColor: good.group.color}" v-if="good.every_day_product!=null&&good.every_day_product.cell!=null&&$route.path!='/operator/history'">{{good.every_day_product.cell.name}}</div>
                               <div v-else>
                                 <div class="adminOrderItem__numbers" v-if="good.files.length!=0">
        <img :src="`https://elimleli2.itfactory.site${good.files[0].thumb_path}`" /> 
        <!-- <img :src="require('../assets/cartitem.jpg')" />  -->
    </div>

                               <div class="cart-item__noimgs" v-else></div>
                               </div>
                                <div class="adminOrderItem__desc">
                                    <b>{{good.name}}</b>
                                    <span>{{good.quantity}} {{decQua(good.quantity)}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="adminOrderItem align-items-center">

                                <div class="adminOrderItem__desc adminOrderItem__desc--center">
                                    <b>{{item.type_of_way.title}}</b>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="adminOrder__btn">
                        <button class="btn-oval me-2" v-if="$route.path == '/operator/assembled'" @click="collected(nextStatus,item.id)">Выдано</button>
                        <button class="btn-oval me-2" v-if="$route.path == '/operator/assembly'" @click="collected(nextStatus,item.id)">Собрано</button>
                        <!-- <button class="btn-oval btn-oval--brown" @click="sendSms(1)">Отправить SMS</button> -->
                        <button class="btn-oval btn-oval--brown ms-2" v-if="$route.path == '/operator/assembled'" data-bs-toggle="modal" data-bs-target="#sms" @click="setSms({id:item.id, user:item})">Отправить SMS</button>
                        <!-- <button class="btn-oval me-2" v-if="$route.path == '/operator/history'" @click="collected(nextStatus,item.id)">Заполнить реестр</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            opened: [],
        };
    },
    props: {
        item: Object,
        nextStatus: Number,
        currentStatus: String
    },
    async mounted() {
     const id = this.$store.state.operator.ordersList[0].id
        this.opened.push(id)
    },
    watch:{
'$store.state.operator.ordersList': {
            //immediate: true,
            async handler() {
              const id = this.$store.state.operator.ordersList[0].id
        this.opened.push(id)
            }
        },
    },
    methods: {
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        },
        async collected(status_id, order_id) {
            const data = {
                'status_id': status_id,
                'order_id': order_id
            }
            await this.$store.dispatch("actionChangeStatus", data);
            await this.$store.dispatch("actionGetListOrder", this.currentStatus);
        },
        setSms(info) {
            this.$store.commit('SET_SMS_INFO', info);
        },
        decQua(count) {
            if (count == 1) {
                return 'порция';
            } else if (count > 1 && count <= 4) {
                return 'порции';
            } else if (count >= 5) {
                return 'порций';
            }
        }
    }
    }
</script>
